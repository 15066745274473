import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"
import SanityBlockContent from "@sanity/block-content-to-react"
import { sanitySerializers, sanityClientConfig } from "../../utils/sanityConfig"
import { MediaQueries } from "../../utils/responsive"
import Ribbon from "./Ribbon"

const StyledShopCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  height: 100%;
  box-shadow: 0 0 15px #50355044, 0 0 25px #6a3f6d44;
  .my-card-header {
    position: relative;
    width: 100%;
    height: 185px;
    .my-card-header-content {
      display: flex;
      height: 60%;
      width: 100%;
      align-items: center;
      h2 {
        position: absolute;
        margin-bottom: 0;
        padding: 3rem 2rem;
        line-height: 1.25;
        font-size: 2.4rem;
        color: ${props => props.theme.colors.pink};
        text-transform: uppercase;
        @media ${MediaQueries.tablet} {
          font-size: 3.6rem;
        }
        span {
          display: block;
          font-size: 2rem;
        }
      }
    }
  }
  .my-card-body {
    padding: 0 2rem 2rem;
    p {
      margin-bottom: 0;
    }
    div {
      margin-top: 2rem;
      p {
        margin-bottom: 0;
      }
    }
  }
`

const ShopCard = props => {
  const { t } = useTranslation("common")
  return (
    <StyledShopCard>
      {props.shop?.name === "Terézváros" && <Ribbon text={"NYITÁS: JÚLIUS"} />}
      <div className="my-card-header">
        <StaticImage
          style={{
            gridArea: "1/1",
            zIndex: -1,
            position: "absolute",
            width: "100%",
            // You can set a maximum height for the image, if you wish.
          }}
          alt=""
          placeholder="blurred"
          src={"../../images/Ticketbe.png"}
          formats={["auto", "webp", "avif"]}
        />
        <div className="my-card-header-content">
          <h2>
            <span>CSERITI PONT</span>
            {props.shop.name}
          </h2>
        </div>
      </div>
      <div className="my-card-body">
        <p>{props.shop.address}</p>
        <p>{props.shop.phone}</p>
        <SanityBlockContent
          key={(Math.random() * 1000 + 12).toString()}
          blocks={props.shop?._rawOpening?.hu}
          projectId={sanityClientConfig.sanity.projectId}
          dataset={sanityClientConfig.sanity.dataset}
          imageOptions={{ w: 200, h: 100, fit: "max" }}
          serializers={sanitySerializers}
        />
        <p className="pt-3">
          <a
            href={props.shop.route}
            target="blank"
            rel="noreferrer noopener"
            alt="Utvonal"
          >
            {t("common:shops.navigation")}
          </a>
        </p>
      </div>
    </StyledShopCard>
  )
}

ShopCard.propTypes = {
  shop: PropTypes.object,
}

export default ShopCard
