import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import ShopCard from "../ShopCard"
import { MediaQueries } from "../../utils/responsive"

const StyledShopHolder = styled(Row)`
  /* margin: ${props => `${props.theme.margins.verticalMobile}px auto`}; */
  @media ${MediaQueries.mobile} {
    margin-top: ${props => `${props.theme.margins.verticalDesktop * 1.2}px`};
    margin-bottom: ${props => `${props.theme.margins.verticalDesktop}px`};
  }
`

const ShopHolderComponent = props => {
  return (
    <StyledShopHolder>
      {props.shops.map((s, i) => (
        <Col
          className="mb-5"
          xs={12}
          sm={6}
          lg={4}
          key={(Math.random() + i).toString()}
        >
          <ShopCard shop={s} />
        </Col>
      ))}
    </StyledShopHolder>
  )
}
ShopHolderComponent.propTypes = {
  shops: PropTypes.arrayOf(PropTypes.object),
}

ShopHolderComponent.defaultProps = {
  shops: [],
}
export default ShopHolderComponent
