import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HomeBackground from "../components/Visuals/homeBackground"
import JumboComponent from "../components/JumboComponent"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "reactstrap"
import ShopHolderComponent from "../components/ShopHolderComponent"
import GoogleMaps from "../components/GoogleMaps"

import HomeContactComponent from "../components/HomeContactComponent"

const ShopsPage = props => {
  const { i18n } = useTranslation("shop")
  const language = i18n.language
  const pageData = props.data.sanityHomePage
  const shopsData = props.data.sanityShopPage
  const pageTitle = shopsData.title.translate
  const seoImage = getImage(shopsData.image)?.images.fallback.src

  return (
    <Layout alternateLink={props.alternateLinks}>
      <HomeBackground />
      <Container>
        <Seo
          title={`Cseriti - ${pageTitle}`}
          lang={language}
          image={seoImage}
        />
        <JumboComponent
          images={shopsData.image}
          slogan={shopsData.title.translate}
          // singleImage={true}
          video={true}
          videoLink={
            language === "hu"
              ? "https://www.youtube.com/embed/R8OYgCm_3yY"
              : "https://www.youtube.com/embed/R8OYgCm_3yY"
          }
        />
        <ShopHolderComponent shops={shopsData.shops} />
        <Row>
          <Col xs={12} className="px-2 px-sm-5">
            <GoogleMaps shops={shopsData.shops} />
          </Col>
        </Row>
      </Container>
      <HomeContactComponent intro={pageData._rawIntro[language]} />
    </Layout>
  )
}

export const query = graphql`
  query ShopsPageQuery($language: String) {
    sanityHomePage(_id: { eq: "d653ce08-c183-4893-a241-7b5d7d931ee6" }) {
      id
      _rawIntro
      mainImages {
        asset {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      _rawSlogan
    }
    sanityShopPage {
      title {
        translate(language: $language)
      }
      image {
        asset {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      shops {
        name
        address
        phone
        _rawOpening
        location
        route
        opening {
          hu {
            children {
              text
            }
          }
        }
      }
    }
  }
`
export default ShopsPage
