import React from "react"
import styled from "styled-components"

const StyledRibbon = styled.div`
  span {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 28px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 150px;
    display: block;
    background: #79a70a;
    background: linear-gradient(#dacde5 0%, #ab4a9c 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 27px;
    right: -36px;
  }
  span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #ab4a9c;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #ab4a9c;
  }
  span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #ab4a9c;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #ab4a9c;
  }
`

const Ribbon = ({ text }) => (
  <StyledRibbon>
    <span>{text}</span>
  </StyledRibbon>
)

export default Ribbon
